<template>
  <!-- 越城区-左边 -->
  <div style="position: relative">
     <div class="space">
      <div class="floorSpace darkBack">
        <p>{{area}}体育场地总面积(平方米)</p>
        <p style="font-size: 0.26rem; color: #20d7fe">
          <countTo
            :startVal="0"
            :endVal="areaDataCon.sitearea"
            :duration="3000"
          ></countTo>
        </p>
      </div>
      <div class="floorSpace darkBack">
        <p>{{area}}人均体育场地面积(平方米)</p>
        <p style="font-size: 0.26rem; color: #20d7fe">
          <countTo
            :startVal="0"
            :endVal="areaDataCon.siteareap"
            :duration="3000"
            :decimals='1'
          ></countTo>
        </p>
      </div>
    </div>
    <div class="space">
      <div class="floorSpace darkBack">
        <p>{{area}}社会体育指导员人数</p>
        <p style="font-size: 0.26rem; color: #20d7fe">
          <countTo
            :startVal="0"
            :endVal="number.people"
            :duration="3000"
          ></countTo>
        </p>
      </div>
      <div class="floorSpace darkBack">
        <p>国家体质监测合格率</p>
        <p style="font-size: 0.26rem; color: #20d7fe">
          <countTo
            :startVal="0"
            :endVal="areaDataCon.constitution"
            :duration="3000"
            :decimals='1'
          ></countTo>%
        </p>
      </div>
    </div>
    <div class="chart">
      <!-- <div class="chartOne darkBack">
        <div>
          <p>全民健身发展指数总排名</p>
        </div>
        <div id="histogram1"></div>
      </div> -->
      <div class="chartOne darkBack">
        <div>
          <p>体育场地面积（平方米）<span class="deadline">（截止{{chartDate1}}）</span></p>
        </div>
        <div id="histogram1"></div>
      </div>
      <div class="chartOne darkBack">
        <p>体育彩票销售额（万元）<span class="deadline">（截止{{chartDate2}}）</span></p>
        <div id="histogram2"></div>
      </div>
      <div class="chartOne darkBack">
        <p>青少年锦标赛即时金牌数（个）<span class="deadline">（截止{{chartDate2}}）</span></p>
        <!-- <p @click="skipoutdoorpain('outdoorpain')">青少年锦标赛即时金牌数(月)</p> -->
        <div id="histogram3"></div>
      </div>
      <div class="chartOne darkBack">
        <p>智慧化场地客流总排名（人次）<span class="deadline">&nbsp</span></p>
        <div id="histogram4"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { show } from "../../../assets/js/animate";
import bus from '/src/assets/js/bus.js'
import {getAjax, timestamp} from "/src/assets/js/websocket";
import {mapState} from 'vuex'
// 引入数字滚动组件
import countTo from "vue-count-to";
export default {
  components: { countTo },
  data() {
    return {
      area:"",
      number: {
        people: 0,
        ratio:0,
      },
      space: {
        num1:0,
        num2:0,
      },
            chartDate1: '',//体育场地统计面积截止日期
            chartDate2: '',//体育彩票销售额统计截止日期
      chartOption: [
        {
          data: [
            { value: "越城区", textStyle: { color: "#2D94D0" } },
            { value: "柯桥区", textStyle: { color: "#2D94D0" } },
            { value: "上虞区", textStyle: { color: "#2D94D0" } },
            { value: "诸暨市", textStyle: { color: "#2D94D0" } },
            { value: "嵊州市", textStyle: { color: "#2D94D0" } },
            { value: "新昌县", textStyle: { color: "#2D94D0" } },
          ],
          number: [0,0,0,0,0,0],
          max: 5,
          color1: "#2D94D0", // y轴左侧轴的颜色
          color2: "#2D94D040", // 网格线的颜色
          color3: "#2D94D0", // 渐变色初始颜色
          color4: "#3AE6CE", // 渐变色末尾颜色
          color5: "#3AE5CE", // 柱状图顶部的颜色
        },
        {
          data: [
            { value: "越城区", textStyle: { color: "#2D94D0" } },
            { value: "柯桥区", textStyle: { color: "#2D94D0" } },
            { value: "上虞区", textStyle: { color: "#2D94D0" } },
            { value: "诸暨市", textStyle: { color: "#2D94D0" } },
            { value: "嵊州市", textStyle: { color: "#2D94D0" } },
            { value: "新昌县", textStyle: { color: "#2D94D0" } },
          ],
          number: [0,0,0,0,0,0],
          max: 5,
          color1: "#E5823A", // y轴左侧轴的颜色
          color2: "#E9AC3140", // 网格线的颜色
          color3: "#E5813A", // 渐变色初始颜色
          color4: "#EFDC29", // 渐变色末尾颜色
          color5: "#EFDB29", // 柱状图顶部的颜色
        },
        {
          data: [
            { value: "越城区", textStyle: { color: "#2D94D0" } },
            { value: "柯桥区", textStyle: { color: "#2D94D0" } },
            { value: "上虞区", textStyle: { color: "#2D94D0" } },
            { value: "诸暨市", textStyle: { color: "#2D94D0" } },
            { value: "嵊州市", textStyle: { color: "#2D94D0" } },
            { value: "新昌县", textStyle: { color: "#2D94D0" } },
          ],
          number: [0,0,0,0,0,0],
          max: 5,
          color1: "#159763", // y轴左侧轴的颜色
          color2: "#3DB56A40", // 网格线的颜色
          color3: "#149663", // 渐变色初始颜色
          color4: "#6BD871", // 渐变色末尾颜色
          color5: "#62D170", // 柱状图顶部的颜色
        },
        {
          data: [
            { value: "越城区", textStyle: { color: "#2D94D0" } },
            { value: "柯桥区", textStyle: { color: "#2D94D0" } },
            { value: "上虞区", textStyle: { color: "#2D94D0" } },
            { value: "诸暨市", textStyle: { color: "#2D94D0" } },
            { value: "嵊州市", textStyle: { color: "#2D94D0" } },
            { value: "新昌县", textStyle: { color: "#2D94D0" } },
          ],
          number:[0,0,0,0,0,0],
          max: 5,
          color1: "#850D86", // y轴左侧轴的颜色
          color2: "#850D864C", // 网格线的颜色
          color3: "#850D86", // 渐变色初始颜色
          color4: "#EE88EF", // 渐变色末尾颜色
          color5: "#EE88EF", // 柱状图顶部的颜色
        },
      ],
      areaId:"",
      peopleTimer:""
    };
  },
  computed: {
    ...mapState(['areaDataCon'])
  },
  methods: {
    skipoutdoorpain(showdata) {
      let _this = this;
      show(showdata, _this);
      $("#amap").hide();
    },
    fitness(id, number) {
      let that = this;
      // let canye = this.$echarts.init(document.getElementById(id));
      let canye = this.$echarts.getInstanceByDom(document.getElementById(id))
      if (canye == null) { // 如果不存在，就进行初始化
          canye = this.$echarts.init(document.getElementById(id));
      }
      var data = number.number;
      // 柱状图字体颜色值
      var fontColor;
      // 数据数组
      let seriesData = [];
      // 柱状图颜色
      let itemStyle = {};
      // 城市数组
      let city = number.data;
      city.forEach((item, index) => {
        if (item.value == window.communityname) {
          itemStyle = {
            normal: {
              color: new that.$echarts.graphic.LinearGradient(0,0,1,0,
                [
                  {
                    offset: 0,
                    color: "#E64141", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#F88197", // 100% 处的颜色
                  },
                ],
                false
              ),
            },
          };
          item.textStyle = { color: "#F88197" };
        } else {
          itemStyle = {
            normal: {
              color: new that.$echarts.graphic.LinearGradient(0,0,1,0,
                [
                  {
                    offset: 0,
                    color: "#2D94D0", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#3AE6CE", // 100% 处的颜色
                  },
                ],
                false
              ),
            },
          };
        }
        seriesData.push({
          value: data[index],
          itemStyle: itemStyle,
        });
      });

      let option = {
        xAxis: {
          // max: 'dataMax',
          show: true,
          type: "value",
          axisLine: {
            //x轴坐标轴
            show: false,
          },
          axisLabel: {
            show: false,
          },

          axisTick: {
            //x轴刻度线
            show: false,
          },
          splitNumber: 4,
          splitLine: {
            // 网格线的颜色
            show: true,
            lineStyle: {
              color: number.color2,
              width: 1,
              type: "solid",
            },
          },
        },
        grid: {
          // 间距
          left: "8%",
          right: "6%",
          top: "6%",
          bottom: "5%",
          containLabel: true,
        },
        yAxis: {
          type: "category",
          data: number.data,
          inverse: true, // 上下反向
          animationDuration: 300,
          animationDurationUpdate: 300,
          max: number.max, // y轴显示显示最大的条数
          // axisLine:{              // y轴左侧的样式
          //     show:true,
          //     lineStyle: {
          //         color: number.color1,
          //     }
          // },
          splitLine: { show: false },
          axisTick: {
            // y轴刻度线
            show: false,
            alignWithLabel: true,
          },
          splitArea: { show: false },
          axisLabel: {
            // y轴字体的颜色
            // margin: 42,//刻度标签与轴线之间的距离
            interval: 0, //横轴信息全部显示
            textStyle: {
              show: true,
              fontFamily: "微软雅黑",
              color: "#7EA7E2",
              fontSize: "0.12rem",
              // align: 'left'
            },
          },
        },
        series: [
          {
            // realtimeSort: true,
            // name: 'X',
            type: "bar",
            barWidth: '36%', // 柱子的宽度
            data: seriesData,
            // itemStyle: {
            //     normal: {
            //         color: new that.$echarts.graphic.LinearGradient(0, 0 , 1, 0, [{
            //             offset: 0,
            //             color: number.color3 // 0% 处的颜色
            //         }, {
            //             offset: 1,
            //             color: number.color4 // 100% 处的颜色
            //         }], false)
            //     }
            // },
            label: {
              formatter: "{c}",
              show: true,
              position: ["50%","-120%"],
              valueAnimation: true,
              color: number.color5, // 柱状图上字体的颜色
              fontSize: "0.12rem",
            },
          },
        ],
        legend: {
          show: true,
        },
        animationDuration: 0,
        animationDurationUpdate: 3000,
        animationEasing: "linear",
        animationEasingUpdate: "linear",
      };

      canye.setOption(option);

      //图表自动缩放
      window.addEventListener("resize", function () {
        canye.resize();
      });
    },
           // 获取体育场地面积
        getSitearea(){
            let that = this;
            let url = window.appHost+`api/v1/screen/Sitearea/area`
            let result = window.ajaxRequest(url)
            // that.chartOption[0].data = [];
            that.chartOption[0].number = [];
            that.chartOption[0].data.forEach((item, index) => {
                if (item.value == that.area) {
                  item.textStyle = { color: "#2D94D0" } 
                }
                for(let i in result.data.data){
                    if (i == item.value) {
                        that.chartOption[0].number[index] = result.data.data[i];
                    }       
                }
            })
            //  for(let i in result.data.data){
            //     that.chartOption[0].data.push(i);
            //     that.chartOption[0].number.push(result.data.data[i]);
            // }
            that.chartDate1 = result.data.deadline;
            that.fitness("histogram1", that.chartOption[0])
        },
        // 获取彩票销售额
        getLotterySale(){
            let that = this;
            let url = window.appHost+`api/v1/screen/Lottery/sale`
            let result = window.ajaxRequest(url)
            // console.log(result);
            // that.chartOption[1].data = [];
            that.chartOption[1].number = [];
            that.chartOption[1].data.forEach((item, index) => {
                if (item.value == that.area) {
                  item.textStyle = { color: "#2D94D0" } 
                }
                for(let i in result.data.data){
                    if (i == item.value) {
                        that.chartOption[1].number[index] = result.data.data[i];
                    }       
                }
            })
            // for(let i in result.data.data){
            //     that.chartOption[1].data.push(i);
            //     that.chartOption[1].number.push(result.data.data[i]);
            // }
            that.chartDate2 = result.data.deadline;
            that.fitness("histogram2", that.chartOption[1])
        },
         // 青少年锦标赛金牌榜
        getTrophy(){
            let that = this;
            let url = window.appHost+`api/v1/screen/Sitearea/trophy`
            let result = window.ajaxRequest(url)
            // console.log(result);
            // that.chartOption[2].data = [];
            that.chartOption[2].number = [];
            that.chartOption[2].data.forEach((item, index) => {
                if (item.value == that.area) {
                  item.textStyle = { color: "#2D94D0" } 
                }
                for(let i in result.data.data){
                    if (i == item.value) {
                        that.chartOption[2].number[index] = result.data.data[i];
                    }       
                }
            })
            // for(let i in result.data.data){
            //     that.chartOption[2].data.push(i);
            //     that.chartOption[2].number.push(result.data.data[i]);
            // }
            that.fitness("histogram3", that.chartOption[2])
        },
         // 获取智慧化场地客流排名
         getAreaFlow(){
            let that = this;
            let url = window.appHost+`api/v1/screen/fitness_index/areaFlow`
            let result = window.ajaxRequest(url)
            // console.log(result);
            // that.chartOption[3].data = [];
            that.chartOption[3].number = [];
            that.chartOption[3].data.forEach((item, index) => {
                if (item.value == that.area) {
                  item.textStyle = { color: "#2D94D0" } 
                }
                result.data.area_list.forEach(i => {
                    if (i.name == item.value) {
                        that.chartOption[3].number[index] = i.full_name_fitness_development_index_num;
                    }    
                })
            })
            // result.data.area_list.forEach((item,index)=>{
            //     that.chartOption[3].data.push(item.name);
            //     that.chartOption[3].number.push(item.full_name_fitness_development_index_num);
            // })
            that.fitness("histogram4", that.chartOption[3])
        },
    // 获取数字信息
    getNumber(id){
      let that = this;
      var data = {
          token: window.getToken(),
          app_id: window.appId,
          time: timestamp(),
      }
      getAjax({
          url: "/map/screen/getHome?area_id="+id,
          method:"GET",
          data:data,
          success:function(res){
            // console.log(res)
            that.number.people = res.data.resumption_num;
            that.number.ratio = res.data.resumption_lv;
            that.space.num1 = res.data.area_total;
            that.space.num2 = res.data.sportsman_main_sum;
            
          },
      })
    }
  },
  mounted() {
      this.area = window.communityname;
      // this.fitness("histogram1", this.chartOption[0]);
      // this.fitness("histogram2", this.chartOption[1]);
      // this.fitness("histogram3", this.chartOption[2]);
      // this.fitness("histogram4", this.chartOption[3]);
      let that = this;
      
      bus.$on('now_area',res=>{
        // console.log(window.communityname)
        that.area = window.communityname;
        // 获取户外排名
        that.getOurdoorRank()
        // 获取云健身时长排名
        that.getCloudRank();
        // 获取健身房时长排名
        that.getGymRank();
        // 获取全民健身发展指数排名
        // that.getDevelopRank();
        // 获取智慧化场地客流排名
        that.getDevelopAreaFlow();
      })
      bus.$on('area_id',res=>{
        // console.log("id",res)
        // 获取数字信息
        that.getNumber(res);
        that.areaId = res;
        
        
      })

      let info = JSON.parse(window.localStorage.getItem("screenUserInfo"));
      if(info.is_city == 0){
        window.new_area_id = info.area_id;
      }
      // 更新地图信息
        let data = {
            token: window.getToken(),
            app_id: window.appId,
            time: timestamp(),
        }
        getAjax({
            url: "/map/screen/getShaoxingStreet?pid="+window.new_area_id,
            method:"GET",
            data:data,
            success:function(res){
                // console.log(res)
                lotteryjson.yuecheng.data.center = [parseFloat(res.data[0].longitude),parseFloat(res.data[0].latitude)]

                lotteryjson.yuecheng.data.points = [];
                res.data.forEach((item,index)=>{
                  lotteryjson.yuecheng.data.points.push({
                        location: [
                            parseFloat(item.longitude),
                            parseFloat(item.latitude)
                        ],
                        name: item.name
                  })  
                })
                // console.log(lotteryjson.yuecheng)
                that.plug.upDataMap(lotteryjson.yuecheng)
            },
        })

      // 获取户外排名
      // that.getOurdoorRank()
      // 获取云健身时长排名
      // that.getCloudRank();
      // 获取健身房时长排名
      // that.getGymRank();
      // 获取全民健身发展指数排名
      // that.getDevelopRank();
      // 获取智慧化场地客流排名
        // that.getDevelopAreaFlow();

        that.getTrophy();//青少年锦标赛
        that.getSitearea()//体育场地总面积
        that.getLotterySale();//彩票销售额
        that.getAreaFlow();//智慧场馆客流

      // 获取数字信息
      if(info.is_city == 0) that.getNumber(info.area_id);
      else that.getNumber(that.areaId);

      that.peopleTimer = setInterval(() => {
        that.getNumber(that.areaId);
      }, 300000);
  },
  beforeDestroy(){
    bus.$off('now_area')
    bus.$off('area_id')
    clearInterval(this.peopleTimer)
  }
};
</script>

<style scoped>
/* 第一行 */
.baitop {
  width: 5.06rem;
  /* height: 1.19rem; */
  text-align: center;
  color: #a8daeb;
  padding: 0.07rem;
  display: flex;
  justify-content: space-between;
}
p {
  margin: 0;
  padding: 0;
}
.baitop > div {
  width: 50%;
  margin-top: 0.12rem;
}

/* 第二行 */
.space,
.chart {
  width: 5.06rem;
  /* height:1.19rem; */
  display: flex;
  justify-content: space-between;
  margin-top: 0.18rem;
  text-align: center;
  flex-wrap: wrap;
}
.floorSpace {
  width: 2.43rem;
  height: 0.97rem;
  font-size: 0.14rem;
  color: #a8daeb;
  display: flex;
  flex-direction: column;
  padding: 0.2rem 0 0.2rem;
  /* justify-content: center; */
}

/* 第三行 图表 */
.chartOne {
  width: 2.43rem;
  height: 2.5rem;
  padding: 0.1rem 0.2rem;
  margin-bottom: 0.2rem;
  color: #ffffff;
  font-size: 0.12rem;
  text-align: left;
}
.chartOne > div {
  height: 0.3rem;
  text-align: left;
}

#histogram1,
#histogram2,
#histogram3,
#histogram4 {
  width: 2.4rem;
  height: 2rem;
  margin-left: -0.2rem;
  margin-top: 0.1rem;
}

.deadline {
  display: block;
    font-size: 0.06rem !important;
}
</style> 